
import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';
import { appSettings } from '../settings';
import { IPageInfoCommon } from '../../types/IComponentTypes';


const getHeaderValue = ( token: string ) => {
  return !appSettings.STAuth ? { headers: getHeaders( token ) } : undefined
}

export const NotificationApi = {

  //get all notifications from the api
  getNotifications: ( payload: IPageInfoCommon ) => {

    let queryParams = `${EUrlParams.Page}=` + payload.page + `&${EUrlParams.Limit}=` + payload.limit;
    queryParams = ( payload.searchCode ? `${EUrlParams.Search}=` + payload.searchCode + '&' : '' ) + queryParams;

    const headers = getHeaderValue( payload.token );

    return AxiosClient
      .post( `/notification/v1/read?${queryParams}`, headers )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: payload.searchCode,
          page: payload.page,
          totalRecords: total,
          recordsPerPage: payload.limit
        }
      } ).catch( ( err ) => {
        if ( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: payload.searchCode,
            page: payload.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.NotificationKey );
      } )
  }
}