import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore, AppDispatch, AppState } from '../../../store';
import { Box,Button, TextField, Checkbox, FormControlLabel, Collapse, Alert, AlertTitle, RadioGroup, Grid, Radio, SelectChangeEvent, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { EActionType, EAction, EPropertyType, ELovValues, excludeLovValues, EApplicability} from '../../../data/Constants';
import { getProperties, createProperty, getPropertyDetails, updateProperty } from '../../../store/states/PropertySlice';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { IError, IManipulationProps } from '../../../../types';
import { setAlertMessage, resetAlertMessage } from '../../../store/states/AlertMessageSlice';
import { resetError } from '../../../store/states/ErrorSlice';
import { ErrorMessage } from '../../ErrorMessage';
import { getEnumOptions, resetAllErrorInfo } from '../../../services/DataHelperFunctions';

//Call the create or update api call to update or create property
function callCreateOrUpdateApi( manipulationType: string, props: IManipulationProps, formData, t: ( message: string ) => string, dispatch: AppDispatch ) {
  delete formData['id'];
  if( manipulationType === EActionType.Edit ) {
    dispatch( updateProperty( {token: props.token,request: {...formData,name:formData.name.trimEnd(),description:formData.description.trim()}} ) ).then( res=>{
      handleApiResponse( res.payload,props,t( 'messages.fail.update' ),t( 'messages.success.update' ), dispatch );
    } )
  }else{
    dispatch( createProperty( {token: props.token, request: {...formData,name:formData.name.trimEnd(),description:formData.description.trim()}} ) ).then( res=>{
      handleApiResponse( res.payload,props,t( 'messages.fail.create' ),t( 'messages.success.create' ), dispatch );
    } )
  }
}

//Getet the latest properties from the db after create or update the properties
function handleApiResponse( res,props: IManipulationProps,failMessage:string,successMessage:string, dispatch: AppDispatch ) {
  const properties = AppStore.getState().dataMaintainance.properties;
  if( res?.data ) {
    dispatch( getProperties( {token: props.token,page: properties.currentPage, limit: properties.recordsPerPage, searchCode: properties.searchKey } ) )
    dispatch( setAlertMessage( {show:true,message:successMessage,type:'success'} ) )
    dispatch( resetDialogMessage() );
  }else{
    const message = res?.error?.message ? res.error.message : failMessage
    dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
  }
}


//Set the initial data while open the create diaglog window
const loadFormData = async ( props: IManipulationProps, setInitialPropertyData, setFormData, setEnumOptions, enumValues ) => {
  let propertyDetails = await AppStore.dispatch( getPropertyDetails( {token: props.token, searchCode: props.id} ) ).unwrap();

  if( !propertyDetails || !propertyDetails.data ) {
    return;
  }
  propertyDetails = propertyDetails.data.properties[0];
  setInitialPropertyData( propertyDetails );
  setFormData( propertyDetails );
  if( propertyDetails.values && propertyDetails.values !== '' ) {
    const options = getEnumOptions( enumValues, propertyDetails.values )
    setEnumOptions( options );
  }
}

//Set alert message state
const setLocalAlertMessage = ( error: IError, setDisplayAlertMessage ) => {
  if( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }
}

//validte the property field  data
const formValidation = ( formValidationProps ) => {
  const { formData, initialPropertyData, stringValidations, setDisableButton, setDisplayNameErrorMessage, setDisplayCodeErrorMessage } = formValidationProps;
  const name = formData.name.trimEnd();
  
  const validName = name.length >= stringValidations.name.min;
  const validCode = formData.code.length >= stringValidations.code.min && formData.code.length <= stringValidations.code.max ;
  const validType = formData.type && formData.type != ''
  const validApplicabilty = formData.applicability && formData.applicability != ''

  let validSelectionType = true
  let validValues = true
  if( formData.type == EPropertyType.Enum ) {
    validSelectionType = formData.selectionType && formData.selectionType != '';
    validValues = formData.values && formData.values != ''
  }

  const detailsUpdated = JSON.stringify( initialPropertyData ) !== JSON.stringify( {...formData,name } )

  setDisplayNameErrorMessage( !validName );
  setDisplayCodeErrorMessage( !validCode );

  const disableButton: boolean = !detailsUpdated || !validName || !validCode || !validType || !validApplicabilty || !validSelectionType || !validValues;
  setDisableButton( disableButton )
}

//Set the state value when change the field in the dialog window
const handleChange = ( event, setFormData, setEnumOptions = null,enumValues = null ) => {
  const name = event.target.name;  
  const value = name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )   
  
  if( name == 'values' ) {
    const options = getEnumOptions( enumValues, value )
    setEnumOptions( options );
  }
  //Whenver Property type changes default, selection Type and values are set to null
  if( name == 'type' ) {
    setFormData( values => ( {...values, 'default': '', 'selectionType': '', 'values': ''} ) ) 
  }
}

//Set the applicability value to the state
const handleChangeApplicability = ( event: SelectChangeEvent, setFormData, formData )=>{
  const tempFormData = JSON.parse( JSON.stringify( formData ) )
  if ( event.target.checked ) {
    if( event.target.value == EApplicability.Master ) {
      tempFormData.applicability = [event.target.value]
    }else{
      tempFormData.applicability.push( event.target.value )
    }
  } else {
    tempFormData.applicability.splice( tempFormData.applicability.findIndex( ( val ) => val === event.target.value ), 1 )
  }
  setFormData( tempFormData )
}

//Renders the button section
const ActionButtons = ( actionButtonsProps )=>{
  const { manipulationType, disableButton, handleSubmit, setDisplayAlertMessage, t } = actionButtonsProps;
  return <>
    <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
      { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
    </Button>
    <Button size="medium" onClick={ ()=> {
      resetAllErrorInfo()
      setDisplayAlertMessage( false ); 
    } } className="text-capitalize"
    >{ t( 'button.cancel' ) } </Button>
  </>
}

const renderEnumFields = ( formData, enumOptions, enumValues, setFormData, setEnumOptions, manipulationType, t )=>{
  const selectionTypes = enumValues?.find( ( value )=>value.code === ELovValues.ESCT ).options;
  const validValues = enumValues?.filter( ( value )=>!excludeLovValues.includes( value.code ) );
  return <>{formData.type == EPropertyType.Enum && <>
    <fieldset className="inputFieldset w-100 mt-1em">
      <legend className="inputLegend" id="selectionType">{t( 'labels.selectionType' ) }<span className="asterisk">*</span></legend>
      {selectionTypes.length > 0 ?
        <RadioGroup
          aria-labelledby="selectionType"
          name="selectionType"
          value={ formData.selectionType } 
          onChange={ ( e ) => {
            handleChange( e, setFormData )
          } }
        > <Grid container spacing={ 2 } className="pt-1">
            {selectionTypes.map( ( value:string ) => {
              return <Grid item xs={ 4 } key={ value.code } className="pt-0"> <FormControlLabel value={ value.code } label={ value.name } disabled = { manipulationType === EActionType.Edit && !formData.isActive } 
                control={ < Radio /> }
              /></Grid>
            } )
            }
          </Grid>
        </RadioGroup> : null}
    </fieldset>
    <fieldset className="inputFieldset w-50 mt-1em">
      <legend className="inputLegend" id="listOfValues">{t( 'labels.listOfValues' ) }</legend>
      <Box className="d-flex flex-wrap pb-2">

        <FormControl required className="w-50" size="small" disabled = { manipulationType === EActionType.Edit && !formData.isActive }>
          <InputLabel>{t( 'labels.valueType' )}</InputLabel>
          <Select label={ t( 'labels.valueType' ) } color="primary" value={ formData.values }
            onChange={ ( e )=> handleChange( e, setFormData, setEnumOptions, enumValues ) } name="values"
          >
            {validValues.map( ( value )=>{
              return <MenuItem value={ value.code } key={ value.code } >{value.name}</MenuItem> 
            } )}
          </Select>
        </FormControl> 

        <FormControl className="w-50 pl-2" size="small" disabled = { manipulationType === EActionType.Edit && !formData.isActive }>
          <InputLabel>{t( 'labels.default' )}</InputLabel>
          <Select color="primary" 
            label={ t( 'labels.default' ) }
            value={ formData.default }
            onChange={ ( e )=> handleChange( e, setFormData ) } name="default"
          >
            {enumOptions.map( ( value )=>{
              return <MenuItem value={ value.code } key={ value.code } >{value.name}</MenuItem> 
            } )}
          </Select>
        </FormControl>  
      </Box>
    </fieldset>
    <br/>
  </>
  }
  </>
}

const renderDefaults = ( formData, types, setFormData, manipulationType, t )=>{
  const BoolPropertyDefaults = ['True','False']
  
  return <>{formData.type == EPropertyType.Bool &&
    <>
      <fieldset className="inputFieldset w-100 mt-1em">
        <legend className="inputLegend" id="default">{t( 'labels.default' ) }</legend>
        {types.length > 0 ?
          <RadioGroup
            aria-labelledby="default"
            name="default"
            value={ formData.default } 
            onChange={ ( e ) => {
              handleChange( e, setFormData )
            } }
          > <Grid container spacing={ 2 } className="pt-1">
              {BoolPropertyDefaults.map( ( value:string ) => {
                return <Grid item xs={ 4 } key={ value } className="pt-0"> <FormControlLabel value={ value } label={ value } disabled = { manipulationType === EActionType.Edit && !formData.isActive }
                  control={ < Radio /> }
                /></Grid>
              } )
              }
            </Grid>
          </RadioGroup> : null}
      </fieldset>
    </>
  }
  {formData.type == EPropertyType.String &&
    <>
      <br/>
      <TextField name="default" label={ t( 'labels.default' ) } variant="outlined" size="small" fullWidth value={ formData.default || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      disabled = { manipulationType === EActionType.Edit && !formData.isActive }
      />
      <br/>
    </>
  }
  {formData.type == EPropertyType.Numeric &&
    <>
      <br/>
      <TextField inputProps={ { type: 'number'} } name="default" label={ t( 'labels.default' ) } variant="outlined" size="small" fullWidth value={ formData.default || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      disabled = { manipulationType === EActionType.Edit && !formData.isActive }
      />
      <br/>
    </>
  }
  </>
}

const renderActiveField = ( formData, initialPropertyData, manipulationType, setFormData, t ) =>{
  return(
    <>{!initialPropertyData.isActive && manipulationType === EActionType.Edit &&
        <> 
          {formData.type != EPropertyType.Enum && <br/> }
          <FormControlLabel
            value={ formData.isActive }
            checked={ !!formData.isActive }
            control={ <Checkbox /> }
            label={ t( 'labels.isActive' ) }
            labelPlacement="end"
            onChange={ ( e )=>{
              handleChange( e, setFormData )
            } }
            name="isActive"
          />
          <br/>
        </>
    }
    </>
  )
}

//Disable the other applicabilities if Master is selected and vice versa
const validateApplicability = ( formData, manipulationType:string, code:string ) => {
  return manipulationType === EActionType.Edit && !formData.isActive || code !== EApplicability.Master && formData.applicability.includes( EApplicability.Master );
}


/**
 * This component to render the Create/Update Property window.
 * @param {IManipulationProps} props Manipulation Props
 * @returns {JSX.Element} a  PropertyManipulation component.
 */
export const PropertyManipulation = ( props: IManipulationProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error );
  const enumValues = useSelector( ( state:AppState ) => state.dataMaintainance.enumListOfValues );
  const manipulationType = props.type; 
  const [formData, setFormData] = useState( {'code':'','name':'','type':'','description':'','values':'','default':'','applicability':[],'origin':'PDM','selectionType':'','isActive':true} );
  const [initialPropertyData, setInitialPropertyData] = useState( {'code':'','name':'','type':'','description':'','values':'','default':'','applicability':[],'origin':'','selectionType':'','isActive':false} );
  const [disableButton, setDisableButton] = useState( true );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState( false );
  const [displayCodeErrorMessage, setDisplayCodeErrorMessage] = useState( false );
  const [enumOptions , setEnumOptions] = useState( [] );
  const stringValidations = {
    code: { min: 3, max: 4 },
    name: {min: 3}
  }

  const types = getEnumOptions( enumValues, ELovValues.ETYP );
  const applicabilities = getEnumOptions( enumValues,ELovValues.EAPP );

  //Load the initial property data when click on property
  useEffect( ()=>{   
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props,setInitialPropertyData,setFormData,setEnumOptions,enumValues )
    }
  },[] )

  //validate the existing data
  useEffect( ()=>{
    formValidation( { formData, initialPropertyData, stringValidations, setDisplayNameErrorMessage, setDisplayCodeErrorMessage, setDisableButton } );
  } )

  //Show the Alert message  if any alert
  useEffect( () => {     
    setLocalAlertMessage( error, setDisplayAlertMessage );
  }, [error] ) 
  
  //Close the create window when click on cancel button
  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => { 
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage() );
    dispatch( resetError() );
    setDisplayAlertMessage( false );
  };
  
  //Call the save Create or update api to create the propeerty
  const handleSubmit = ( event ) => {
    event.preventDefault();
    dispatch( resetError() ); 
    setDisplayAlertMessage( false );
    callCreateOrUpdateApi( manipulationType,props,formData,t,dispatch )
  }

  //Set applicability value while change the value
  const handleApplicabilityChange = ( event: SelectChangeEvent ) => {
    handleChangeApplicability( event, setFormData, formData )
  };

  return <>
    <Box className="manipulationBox pt-1">     
      <Box className="manipulationForm">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ handleClose }>
            <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType }/> </AlertTitle>
          </Alert>
        </Collapse>
        
        <TextField name="code" label={ t( 'labels.code' ) } required variant="outlined" size="small" fullWidth value={ formData.code || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } } disabled={ manipulationType === EActionType.Edit }
        /><br/>
        <Collapse in={ displayCodeErrorMessage && formData.code.length > 0 }>
          <Alert className="errorMessage" severity="error">
            <AlertTitle>{t( 'messages.codeErrorMessage' )} </AlertTitle>
          </Alert>
        </Collapse>
        <br/>
        <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" fullWidth value={ formData.name || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } } disabled={ manipulationType === EActionType.Edit && !formData.isActive }
        /><br/>
        <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
          <Alert className="errorMessage" severity="error">
            <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
          </Alert>
        </Collapse>
        <br/>
       
        <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" fullWidth value={ formData.description || '' } onChange={ ( e )=>{
          handleChange( e, setFormData )
        } } disabled = { manipulationType === EActionType.Edit && !formData.isActive }
        />
        {
          <fieldset className="inputFieldset w-100 mt-1em">
            <legend className="inputLegend" id="applicability">{t( 'labels.applicability' ) } <span className="asterisk">*</span></legend>
            {
              <RadioGroup
                aria-labelledby="applicability"
                name="applicability"
                value={ formData.applicability } 
              
              > <Grid container spacing={ 2 } className="pt-1">
                  {applicabilities.map( ( value ) => {
                    return <Grid item xs={ 4 } key={ value } className="pt-0">
                      <FormControlLabel value={ value.code } label={ value.name } 
                        disabled = { validateApplicability( formData, manipulationType, value.code ) }
                        control={ <Checkbox checked={ formData.applicability.includes( value.code ) } onChange={ ( e ) => {
                          handleApplicabilityChange( e )
                        } }
                        /> }
                      /></Grid>
                  } )
                  }
                </Grid>
              </RadioGroup>}
          </fieldset>
        }
        {
          <fieldset className="inputFieldset w-100 mt-1em">
            <legend className="inputLegend" id="type">{t( 'labels.type' ) } <span className="asterisk">*</span><span style={ {color: 'red'} }>{manipulationType === EActionType.Create ? t( 'labels.restrictUpdate' ) : ''}</span></legend>
            {types.length > 0 ?
              <RadioGroup
                aria-labelledby="type"
                name="type"
                value={ formData.type } 
                onChange={ ( e ) => {
                  handleChange( e, setFormData, setEnumOptions,enumValues )
                } }
              > <Grid container spacing={ 2 } className="pt-1">
                  {types.map( ( value:string ) => {
                    return <Grid item xs={ 4 } key={ value.code } className="pt-0">
                      {/*disable Type when edit */}
                      <FormControlLabel value={ value.code } label={ value.name } disabled = { manipulationType === EActionType.Edit }
                        control={ < Radio /> }
                      />
                    </Grid>
                  } )
                  }
                </Grid>
              </RadioGroup> : null}
          </fieldset>
        }
        {renderDefaults( formData, types, setFormData, manipulationType, t )}
        {renderEnumFields( formData, enumOptions, enumValues, setFormData, setEnumOptions, manipulationType, t )}
        { renderActiveField( formData, initialPropertyData, manipulationType, setFormData, t )}
        <Box className="formActionButtons">
          { ActionButtons( { manipulationType, disableButton, handleSubmit, setDisplayAlertMessage, t } ) }          
        </Box>
      </Box>
    </Box>
  </>
}