import { handleError, handlePaginatedData } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import { getHeaders } from './HttpUtil';

export const ProductAssociationApi = {

  getProductCatalog: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.ProductSearch}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/product/v1/catalog?${queryParams}`,
        { },
        { 
          baseURL: appSettings.ConfiguratorEndpoint,
          //headers: { 'Authorization': `Bearer ${action.token}` } 
        }
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return handlePaginatedData( response, action, total )
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.ProductCatalogKey );
      } )
  },

  getProductConfiguration: ( action: { token: string, page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/configuration/v1/read?${queryParams}`,
        { },
        !appSettings.STAuth ? { headers: getHeaders( action.token ) } : undefined
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return handlePaginatedData( response, action, total )
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.ProductConfigurationKey );
      } )
  }
}