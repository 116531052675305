import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFeaturePropertyRows, getProductPropertyAssociationRows, updateMaintenanceData, updateMaintenanceSearchData, updateFeaturePropertyAssociation } from '../../services/DataHelperFunctions';
import { ProductAssociationApi } from '../../api/ProductAssociationApi';
import { getImages } from './ImagesSlice';
import { getSalesText } from './SalesTextSlice';
import { getProperties, getPropertyAssociations, getFeaturePropertyAssociations, getAllProperties } from './PropertySlice';
import { getPrice, getPriceListMap } from './PriceSlice';
import { getCountryList, getMarkets, getMarketAssociation } from './MarketSlice';
import { getLanguageAssociation, getLanguages } from './LanguageSlice';
import { compareProductHierarchy, getProductHierarchy } from './ProductHierarchySlice';
import { DataMaintenanceApi } from '../../api/DataMaintenanceApi';

const initialState = {
  data: [],
  salesText: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  images: { 
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  productHierarchy: {    
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  compareProductHierarchy:{
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  prices: { 
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  languageAssociation: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  languages: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  priceListMap: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  productCatalog: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  productConfiguration: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  markets: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  marketAssociation: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  countries:[],
  uploadFile: {
    file: {},
    importDropzone:null,
  },
  chunkUpload:{
    type:'',
    fileName:'',
    fileId:'',
    isUploaded: false,
    uploadValue:0,
    isUploadProgress:false,
  }, 
  properties: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{},
  },
  propertyFields:{
    types:[],
    applicabilities:[]
  },
  propertyAssociation: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{},
  }, 
  featurePropertyAssociation: {
    data:{},
    totalCount:'',
    currentPage:'',
    recordsPerPage:'',
    searchKey:'',
    searchData:{}
  },
  propertyList:[],
  enumListOfValues:[]
}

//To get Product Catalog paginated data
export const getProductCatalog = createAsyncThunk( 'dataMaintenance/getProductCatalog', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return ProductAssociationApi.getProductCatalog( action );
} )

//To get Product Configuration paginated data
export const getProductConfiguration = createAsyncThunk( 'dataMaintenance/getProductConfiguration', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return ProductAssociationApi.getProductConfiguration( action );
} )

//To Get list of values for a Enum type properties
export const getEnumListOfValues = createAsyncThunk( 'dataMaintenance/getEnumListOfValues', async () => {
  return DataMaintenanceApi.getEnumListOfValues( );
} );

const dataMaintenanceSlice = createSlice( {
  name: 'dataMaintenance',
  initialState,
  reducers: {
    resetCompareProductHierarchy( state ) {
      state.compareProductHierarchy = initialState.compareProductHierarchy
    },
    setUploadFile( state, action ) {
      state.uploadFile.file = action.payload.file; 
      state.uploadFile.importDropzone = action.payload.importDropzone;
    },
    removeUploadFile( state ) {
      state.uploadFile.file = initialState.uploadFile.file;
      state.uploadFile.importDropzone = initialState.uploadFile.importDropzone;
    },
    setChunkFileUpload( state, action ) {
      state.chunkUpload = action.payload
    }
  },
  extraReducers: ( builder ) =>{
    builder.addCase( getSalesText.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'salesText' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'salesText' );
        }
      }
    } );
    builder.addCase( getImages.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'images' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'images' );
        }
      }
    } );
    builder.addCase( getProductHierarchy.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'productHierarchy' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'productHierarchy' );
        }
      }
    } );
    builder.addCase( compareProductHierarchy.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'compareProductHierarchy' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'compareProductHierarchy' );
        }
      }
    } );
    builder.addCase( getPrice.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'prices' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'prices' );
        }
      }
    } );
    builder.addCase( getPriceListMap.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'priceListMap' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'priceListMap' );
        }
      }
    } );
    builder.addCase( getCountryList.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.countries = action.payload.data
      }
    } );
    builder.addCase( getMarkets.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'markets' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'markets' );
        }
      }
    } );
    builder.addCase( getMarketAssociation.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'marketAssociation' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'marketAssociation' );
        }
      }
    } );
    builder.addCase( getLanguages.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'languages' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'languages' );
        }
      }
    } );
    builder.addCase( getLanguageAssociation.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'languageAssociation' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'languageAssociation' );
        }
      }
    } );
    builder.addCase( getProductCatalog.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'productCatalog' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'productCatalog' );
        }
      }
    } );
    builder.addCase( getProductConfiguration.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'productConfiguration' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'productConfiguration' );
        }
      }
    } );
    builder.addCase( getProperties.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        action.payload.data = action.payload.data.properties;
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'properties' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'properties' );
        }
      }
    } );
    builder.addCase( getPropertyAssociations.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        action.payload.data = getProductPropertyAssociationRows( action.payload.data )
        if( !action.payload.searchKey ) {
          return updateMaintenanceData( state, action.payload, 'propertyAssociation' );
        } else {
          return updateMaintenanceSearchData( state, action.payload, 'propertyAssociation' );
        }
      }
    } );
    builder.addCase( getFeaturePropertyAssociations.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        action.payload.data = getFeaturePropertyRows( action.payload.data, action.payload.searchKey )    
        return updateFeaturePropertyAssociation( state, action.payload, 'featurePropertyAssociation' )
      } else{
        const payload = { data:[], searchKey: action.payload.error.action.searchCode, page:  action.payload.error.action.page, totalRecords: 1 }
        return updateFeaturePropertyAssociation( state, payload, 'featurePropertyAssociation' )
      }
    } );
    builder.addCase( getAllProperties.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.propertyList = action.payload.data.properties;
      }
    } );
    
    builder.addCase( getEnumListOfValues.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.enumListOfValues = action.payload.data;
      }
    } );
  }
} )

export const { resetCompareProductHierarchy, setUploadFile, removeUploadFile, setChunkFileUpload } = dataMaintenanceSlice.actions;

export default dataMaintenanceSlice.reducer