import { LegendItem } from '../../types';

export const styleConstants = {
  extraSmallHeaderHeight: '89px',
  headerHeight: '105px'
};

export const popperModifier = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: false,
      rootBoundary: 'viewport',
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: true,
      rootBoundary: 'document',
    },
  },
]

export enum ECellType {
  Link = 'Link',
  Checkbox = 'Checkbox',
  Boolean = 'Boolean',
  Channels = 'Channels',
  Chips = 'Chips',
  Images = 'Images',
  LongSalesText = 'LongSalesText',
  ShortSalesText = 'ShortSalesText',
  Price = 'Price',
  ArrayChips = 'ArrayChips',
  ObjectChips = 'ObjectChips',
  Date = 'Date',
  Update = 'Update',
  Details = 'Details',
  MaxDate = 'MaxDate',
  Text = 'Text',
  Tooltip='Tooltip'
}

export enum ECellSubType {
  Roles = 'Roles',
  Channels = 'Channels',
  AssociatedMarkets = 'AssociatedMarkets',
  AssociatedLanguages = 'AssociatedLanguages',
  Languages = 'Languages',
  Countires = 'Countires',
  Images = 'Images'
}

export enum EFileSizeMB {
  MB5 = 5,
  MB10 = 10,
  MB20 = 20
}

export enum EFileSizeBytes{
  KB1500=1500000,
  KB50=50000,
  KB2000=2000000
} 

export enum EImportStatus{
  Success='success',
  Error='error',
  InvalidType='invalidType',
  InvalidSize='invalidSize',
  InvalidFileName = 'invalidFileName',
  InvalidZipFileName = 'invalidZipFileName'
}

export const RecordLength = [ 15, 25, 50, 100, 250 ];

export const FillFieldsWithValueAsNo = ['isActive']; 

export enum ECompareStatusCellValues { //CompareStatus PH Cell Values
  Status = 'compareStatus',
  Added = 'Added',
  Updated = 'Updated',
  Deleted = 'Deleted',
  ModifiedCells ='modifiedCells'
}

export enum EActivity{
  Publish = 'Publish', //Publish PH
  Discard = 'Discard' //Discards PH
}

export const ComparePHLegend : LegendItem[] = [
  {label: 'Added', color: 'lightgreen', shape: 'square'},
  {label: 'Updated', color: 'yellow', shape: 'square'},
  {label: 'Deleted', color: 'pink', shape: 'square'}
]

export const TableHeaders = {
  users: {
    main: [
      {
        field: 'name', width:200, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'emailId', width:300, sort:true, searchable:true
      }, {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'isAdmin', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'isService', width:100, sort:true, type: ECellType.Boolean
      },
      {
        field: 'isAce', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'isPdm', width:100, sort:true, type: ECellType.Boolean
      },
      {
        field: 'roles', width:500, sort:true, type: ECellType.ObjectChips, subType: ECellSubType.Roles, detailedViewHeader: 'roles', hasIcon: true
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'lastLogin', width:180, sort:true, type: ECellType.Date
      }
    ]},
  roleAssociationTab: {
    main: [
      {
        field: 'name'
      }, {
        field: 'description'
      }
    ]},
  roles: {
    main: [
      {
        field: 'name', width:250, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'description', width:250, sort:true, searchable:true
      }, {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'isDefault', width:100, sort:true, type: ECellType.Boolean
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      },

    ]},
  apikeys: {
    main: [
      {
        field: 'key', width:200, searchable:true, type: ECellType.Link
      }, {
        field: 'name', width:200, sort:true, searchable:true
      }, {
        field: 'description', width:250, sort:true, searchable:true
      }, {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'applicationName', width:200, sort:true
      }, {
        field: 'userEmail', width:300, sort:true
      },{
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      },
    ]},
  applications: {
    main: [
      {
        field: 'name', width:150, searchable:true, sort:true, type: ECellType.Link
      }, {
        field: 'description', width:250, searchable:true, sort:true
      },{
        field: 'channelName', width:150, sort:true
      },
      {
        field: 'views', width:150, sort:true,type: ECellType.ArrayChips,hasIcon:false
      }
      , {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  salesText: {
    main: [
      {
        field: 'materialCode', width:150, sort:true, searchable:true
      }, {
        field: 'countryCode', width:150, sort:true
      }, {
        field: 'languageCode', width:150, sort:true
      }, {
        field: 'shortSalesText', width:350, sort:true
      }, {
        field: 'longSalesText', width:100, filter: false, type:ECellType.LongSalesText
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  images: {
    main: [
      {
        field: 'materialCode', width:200, sort:true, searchable:true
      }, {
        field: 'materialImages', width:580, sort:true, filter:true, type:ECellType.Images
      }, {
        field: 'details', width:60, type: ECellType.Details, subType: ECellSubType.Images, detailedViewHeader: 'images'
      }, {
        field: 'lastUpdatedOn', width:180, sort:true, type: ECellType.MaxDate, key: 'materialImages'
      }],
    details: [ 
      {
        field: 'image', width:'30%', cellType: ECellType.Link, key: 'url'
      }, {
        field: 'createdBy', width:'15%'
      }, {
        field: 'createdOn', width:'20%', cellType: ECellType.Date
      }, {
        field: 'modifiedBy', width:'15%'
      }, {
        field: 'modifiedOn', width:'20%', cellType: ECellType.Date
      }]
  },
  productHierarchy: {
    main: [
      {
        field: 'sc', width:125, sort:true
      }, {
        field: 'pd', width:125, sort:true
      }, {
        field: 'bs', width:125, sort:true
      }, {
        field: 'bu', width:125, sort:true, searchable:true
      }, {
        field: 'mag', width:125, sort:true
      }, {
        field: 'ag', width:125, sort:true, searchable:true
      }, {
        field: 'description', width:350, sort:true
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  compareProductHierarchy: {
    main: [
      {
        field: 'sc', width:100, sort:true, index:1, type:ECellType.Update
      }, {
        field: 'pd', width:100, sort:true, index:2, type:ECellType.Update
      }, {
        field: 'bs', width:100, sort:true, index:3, type:ECellType.Update
      }, {
        field: 'bu', width:100, sort:true, searchable:true, index:4, type:ECellType.Update
      }, {
        field: 'mag', width:100, sort:true, index:5, type:ECellType.Update
      }, {
        field: 'ag', width:100, sort:true, searchable:true, index:6, type:ECellType.Update
      }, {
        field: 'description', width:300, sort:true, index:7, type:ECellType.Update
      }]
  },
  prices: {
    main: [
      {
        field: 'materialCode', width:250, flex:3, sort:true, searchable:true
      }, {
        field: 'countryCode', width:250, flex:3, sort:true
      }, {
        field: 'currencyCode', width:250, flex:3, sort:true
      }, {
        field: 'price', width:250, flex:3, sort:true, type: ECellType.Price
      },{
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  priceListMap: {
    main: [
      {
        field: 'country', width:150, sort:true, searchable:true, type: ECellType.Link
      },{
        field: 'map', width:150, sort:true
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  markets: {
    main: [
      {
        field: 'code', searchable:true, width:250, sort:true, type: ECellType.Link
      }, {
        field: 'name', searchable:true, width:250, sort:true
      }, {
        field: 'description', searchable:true, width:250, sort:true
      }, {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      },{
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  marketAssociation: {
    main: [
      {
        field: 'productCode', width:200, searchable:true, sort:true, type:ECellType.Link
      }, {
        field: 'markets', width:580, sort:true, type: ECellType.ObjectChips, hasIcon: false
      }, {
        field: 'details', width:60, type: ECellType.Details, subType: ECellSubType.AssociatedMarkets, detailedViewHeader: 'associatedMarkets'
      }, {
        field: 'lastUpdatedOn', width:180, sort:true, type: ECellType.MaxDate, key: 'markets'
      }],
    details: [ 
      {
        field: 'market', width:'20%', cellType: ECellType.Text, key: 'code'
      }, {
        field: 'createdBy', width:'20%'
      }, {
        field: 'createdOn', width:'20%', cellType: ECellType.Date
      }, {
        field: 'modifiedBy', width:'20%'
      }, {
        field: 'modifiedOn', width:'20%', cellType: ECellType.Date
      }]
  },
  languages: {
    main: [
      {
        field: 'code', width:250, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'name', width:250, sort:true, searchable:true
      }, {
        field: 'description', width:250, sort:true, searchable:true
      }, {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  languageAssociation: {
    main: [
      {
        field: 'productCode', width:200, sort:true, searchable:true, type:ECellType.Link
      }, {
        field: 'languages', width:580, sort:true, type: ECellType.ObjectChips, hasIcon: false
      }, {
        field: 'details', width:60, type: ECellType.Details, subType: ECellSubType.AssociatedLanguages, detailedViewHeader: 'associatedLanguages'
      }, {
        field: 'lastUpdatedOn', width:180, sort:true, type: ECellType.MaxDate, key: 'languages'
      }], 
    details: [ 
      {
        field: 'language', width:'20%', cellType: ECellType.Text, key: 'code'
      }, {
        field: 'createdBy', width:'20%'
      }, {
        field: 'createdOn', width:'20%', cellType: ECellType.Date
      }, {
        field: 'modifiedBy', width:'20%'
      }, {
        field: 'modifiedOn', width:'20%', cellType: ECellType.Date
      }]
  },
  productCatalog: {
    main: [
      {
        field: 'productId', width:150, sort:true, searchable:true
      }, {
        field: 'productDescription', width:200, sort:true, searchable:true
      }, {
        field: 'agCode', width:150, sort:true
      }, 
      {
        field : 'channels', width:150, sort:true,type: ECellType.ArrayChips,hasIcon:false
      },
      {
        field: 'languages', width:300, sort:true, type: ECellType.ObjectChips, subType: ECellSubType.Languages, detailedViewHeader: 'languages', hasIcon: true
      }, {
        field: 'countries', width:700, sort:true, type: ECellType.ObjectChips, subType: ECellSubType.Countires, detailedViewHeader: 'countries', hasIcon: true
      },
      {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  productConfiguration: {
    main: [
      {
        field: 'configurationId', width:275, searchable:true, sort:true
      }, {
        field: 'name', width:250, sort:true
      }, {
        field: 'state', width:100, sort:true
      }, {
        field: 'productId', width:100, searchable:true, sort:true
      }, {
        field: 'productDescription', width:150, searchable:true, sort:true
      }, {
        field: 'countryCode', width:100, sort:true
      }, {
        field: 'countryName', width:200, sort:true
      }, {
        field: 'createdFromName', width:375, sort:true
      }, {
        field: 'createdFromId', width:275, sort:true
      }, {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:200, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:200, sort:true, type: ECellType.Date
      }]
  },
  featureFlags: {
    main: [
      {
        field: 'flagName', width:150, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'description', width:250, searchable:true, sort:true
      }, {
        field: 'applicationName', width:150, sort:true
      }, {
        field: 'page', width:150, sort:true
      }, {
        field: 'isActive', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'isVisible', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'isEditable', width:100, sort:true, type: ECellType.Boolean
      }, {
        field: 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  ace: {
    main: [
      {
        field: 'code', width:300, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'type', width:150, sort:true,searchable:true
      }, {
        field: 'action', width:150, sort:true,searchable:true
      }, {
        field: 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      },
      {
        field: 'processedOn', width:180, sort:true, type: ECellType.Date
      }
    ]
  },
  properties: {
    main: [
      {
        field: 'code', width:150, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'name', width:150, sort:true, searchable:true
      }, {
        field: 'type', width:100, sort:true
      }, 
      {
        field: 'description', width:200, sort:true, type: ECellType.Tooltip
      },
      {
        field: 'values', width:150, sort:true,type:ECellType.Text
      },
      {
        field: 'applicability', width:150, sort:true, type:ECellType.ArrayChips, hasIcon:false
      },
      {
        field: 'default', width:100, sort:true
      },
      {
        field: 'origin', width:100, sort:true
      },
      {
        field: 'isActive', width:100, sort:true,type: ECellType.Boolean
      },
      {
        field : 'createdBy', width:200, sort:true
      }, {
        field: 'modifiedBy', width:200, sort:true
      }, {
        field: 'createdOn', width:180, sort:true, type: ECellType.Date
      }, {
        field: 'modifiedOn', width:180, sort:true, type: ECellType.Date
      }]
  },
  propertyAssociation: {
    main: [
      {
        field: 'productCode', width:200, sort:true, searchable:true, type: ECellType.Link
      }, {
        field: 'description', width:200, sort:true,searchable:true
      }
    ]
  },
  featurePropertyAssociation: {
    main: [
      {
        field: 'code', width:150, sort:true, searchable:true
      }, {
        field: 'description', width:250, sort:true,searchable:true
      }
    ]
  }
}

export const foreignKeysForTable = {
  users: 'emailId',
  roles: 'name',
  apikeys: 'id',
  applications: 'name',
  languageAssociation: 'productCode',
  productHierarchy: 'sc',
  prices: 'materialCode',
  images: 'materialCode',
  salesText: 'materialCode',
  languages:'code',
  priceListMap:'country',
  productCatalog:'productId',
  productConfiguration:'configurationId',
  markets:'code',
  marketAssociation: 'productCode',
  featureFlags: 'flagName',
  properties:'code',
  propertyAssociation: 'productCode',
  featurePropertyAssociation: 'code'

}

export const foreignKeysForInfoIcon = {
  roles: {
    roles: 'name'
  },
  marketAssociation: {
    markets: 'productCode'
  },
  languageAssociation: {
    languages: 'productCode'
  },
  productCatalog: {
    languages: 'productId',
    countries: 'productId',
  }
}

export const searchFields = {
  users: ['name', 'emailId'],
  roles: ['name', 'description'],
  apikeys: ['key', 'name', 'description'],
  applications: ['name', 'description'],
  salesText: ['materialCode'],
  images: ['materialCode'],
  productHierarchy: ['bu', 'ag'],
  compareProductHierarchy: ['bu', 'ag'],
  prices: ['materialCode'],
  priceListMap: ['country'],
  markets: ['code', 'name', 'description'],
  marketAssociation: ['productCode'],
  languages: ['code', 'name', 'description'],
  languageAssociation: ['productCode'],
  productCatalog: ['productId', 'productDescription'],
  productConfiguration: ['productId', 'productDescription', 'configurationId'],
  featureFlags: ['flagName', 'description'],
  ace: ['code','action','type'],
  properties: ['code','name'],
  propertyAssociation: ['productCode','description'],
  featurePropertyAssociation: ['code','description']
}

export enum EActionType {
  Edit = 'Edit',
  Create = 'Create',
  Delete = 'Delete',
  View = 'View',
  Compare = 'Compare'
}

export enum EDataMaintenanceType {
  SalesText = 'salesText',
  Images = 'images',
  ProductHierarchy = 'productHierarchy',
  CompareProductHierarchy='compareProductHierarchy',
  LanguageAssociation = 'languageAssociation',
  Prices = 'prices',
  Language = 'languages',
  PriceListMap = 'priceListMap',
  ProductCatalog = 'productCatalog',
  ProductConfiguration = 'productConfiguration',
  Market ='markets',
  MarketAssociation = 'marketAssociation',
  Properties = 'properties',
  PropertyAssociation = 'propertyAssociation',
  FeaturePropertyAssociation = 'featurePropertyAssociation',
  TechnicalInfo = 'technicalInfo'
}

export enum ENotification{
  Notification='Notification',
  Ace='ace'
}

export enum EDataTableType {
  Users = 'users',
  Roles = 'roles',
  ApiKeys = 'apikeys',
  Applications = 'applications',
  Languages = 'languages',
  SalesText = 'salesText',
  Images = 'images',
  ProductHierarchy = 'productHierarchy',
  LanguageAssociation = 'languageAssociation',
  Prices = 'prices',
  PriceListMap = 'priceListMap',
  ProductCatalog = 'productCatalog',
  ProductConfiguration = 'productConfiguration',
  Markets = 'markets',
  MarketAssociation = 'marketAssociation',
  FeatureFlags = 'featureFlags',
  Ace='ace',
  Properties='properties',
  PropertyAssociation='propertyAssociation',
  FeaturePropertyAssociation='featurePropertyAssociation'
}

export const tabsList = [
  {
    id:'administration',
    childs:['users','roles','apikeys','applications']
  },
  {
    id:'dataMaintenance',
    childs:['salesTexts','prices','images','productHierarchy','priceListMap', 'technicalInfo']
  },
  {
    id:'product',
    childs:['market','marketAssociation','languageAssociation','language','productCatalog','productConfiguration','properties']
  },
  {
    id:'featureFlags',
    childs:['featureFlags']
  },
  {
    id:'notification',
    childs:['ace']
  },
  {
    id:'configuration',
    childs:[]
  }
]

export const administrationTabs = [
  {id:1, name:'User Management', path:'/users'},
  {id:2, name:'Role Management', path:'/roles'},
  {id:3, name:'API Key Management', path:'/apikeys'},
  {id:4, name:'Application Management', path:'/applications'}
]

export const dataMaintenanceTabs = [
  {id:1, name:'Sales Text', path:'/salesTexts'},
  {id:2, name:'Image', path:'/images'},
  {id:3, name:'Product Hierarchy', path:'/productHierarchy'},
  {id:4, name:'Price', path:'/prices'},
  {id:5, name:'Price List Map', path:'/priceListMap'},
  {id:6, name: 'Technical Info', path: '/technicalInfo'}
]

export const productTabs = [
  {id:1, name:'Market', path:'/market'},
  {id:2, name:'Market Association', path:'/marketAssociation'},
  {id:3, name:'Language', path:'/language'},
  {id:4, name:'Language Association', path:'/languageAssociation'},
  {id:5, name:'Product Catalog', path:'/productCatalog'},
  {id:6, name:'Product Configuration', path:'/productConfiguration'},
  {id:7, name:'Properties', path:'/properties'},
  {id:8, name:'Property Association', path:'/propertyAssociation'},
]

export const fearureFlagTabs = [
  {id:1, name:'Feature Flags', path:'/featureFlags'}
]

export const notificationTabs = [
  {id:1, name:'Ace', path:'/ace'}
]


export const deleteFields = {
  roles: [
    {
      field:'isDefault',
      inverseCheck : false
    }, 
    {
      field:'isActive',
      inverseCheck : true
    }
  ],
  users: {
    field:'isActive',
    inverseCheck : true
  },
  apikeys: {
    field:'isActive',
    inverseCheck : true
  },
  applications: {
    field:'isActive',
    inverseCheck : true
  },
  languages:{
    field:'isActive',
    inverseCheck : true
  },
  markets:{
    field:'isActive',
    inverseCheck : true
  },
  featureFlags:{
    field:'isActive',
    inverseCheck : true
  },
  properties:{
    field:'isActive',
    inverseCheck : true
  }
}

export enum ECommonDialogType {
  ApiKeyCreateSuccess = 'apiKeyCreateSuccess',
  EditLanguageAssociation = 'editLanguageAssociation',
  Image = 'image',
  LongSalesText = 'LongSalesText',
  ShortSalesText = 'ShortSalesText',
  Languages = 'languages',
  Markets = 'markets',
  EditMarketAssociation = 'editMarketAssociation',
  EditPriceListMap = 'editPriceListMap',
  Error = 'error',
  Notification='notification',
  Ace='ace',
  Properties='properties',
  PropertyAssociation='propertyAssociation',
  FeaturePropertyAssociation='featurePropertyAssociation',
  DeletePrices = 'deletePrices'
}

export enum ETabValue{ 
  ShortSalesText = 'ShortSalesText',
  LongSalesText = 'LongSalesText',
  AdministrationTab = 'administration'
}

export enum EBrowserType{
  Chrome = 'Chrome',
  Edge= 'Edge',
  Safari= 'Safari',
}

export enum EModuleType{
  Administration = 'administration',
  Datamaintainance = 'dataMaintainance',
  FeatureFlags = 'featureFlags',
  Notifications = 'notifications'
}

export enum EAction{
  Read = 'read',
  Create = 'create',
  Update = 'update',
  Get = 'get'
}

export enum EApiType{
  UserKey = 'userKey',
  RoleKey = 'roleKey',
  ApiKey = 'apiKey',
  ApplicationKey = 'applicationKey',
  SalesTextKey = 'salestextKey',
  ImagesKey = 'imagesKey',
  ProductHierarchyKey = 'productHierarchyKey',
  CompareProductHierarchyKey = 'compareProductHierarchyKey',
  PriceKey = 'priceKey',
  PriceListMapKey = 'priceListMapKey',
  MarketKey = 'marketKey',
  MarketAssociationKey = 'marketAssociationKey',
  LanguageKey = 'languageKey',
  LanguageAssociationKey = 'languageAssociationKey',
  ProductCatalogKey = 'productCatalogKey',
  ProductConfigurationKey = 'productConfigurationKey',
  FeatureFlagsKey = 'featureFlagsKey',
  NotificationKey='notificationKey',
  PropertyKey='propertyKey',
  PropertyAssociationKey='propertyAssociationKey',
  FeaturePropertyAssociationKey='featurePropertyAssociationKey',
}

export enum EHttpCode{
  Status204 = 204,
  Status400 = 400,
  Status401 = 401,
  Status403 = 403,
  Status404 = 404
}

export enum EUrlParams{
  ConfigurationId = 'cid',
  ViewId = 'viewId',
  ApiKey = 'cc',
  Model = 'model',
  Token = 'at',
  HeaderLess = 'hl',
  Language = 'l',
  Country = 'c',
  Page = 'Page',
  Limit = 'Limit',
  Search = 'search',
  ProductSearch = 'productSearch',
  MaterialCode = 'materialCode'
}

export enum EEnvironments{
  Dev = 'Dev',
  Prod = 'Prod',
  Test = 'Test',
  Demo = 'Demo',
}

export enum EPageNames{
  UserManagement = '/users'
}


export enum EFileFormat{
  Zip='.zip',
  Xlsx='.xlsx'
}

export enum ELocalStore{
  IsUnSupportedBrowserAck='isUnsBrowAck'
}

export enum ELovValues{
  ETYP = 'ETYP',
  EAPP = 'EAPP',
  ESCT = 'ESCT',
  ECHT = 'ECHT',
  EVIEW = 'EVIEW'
}

export enum EApplicability{
  Master = 'MSTR'
}

export const excludeLovValues = ['ETYP','EAPP','ESCT']

export enum EPropertyType{
  Bool = 'BOOL',
  Enum = 'ENUM',
  String = 'STR',
  Numeric = 'NUM'
}

export const RegExPatterns = {
  Url: /^(https?|ftp):\/\/\w+(\.\w+)*(:\d+)?\/?(\/[.\w]*)*$/,
  Email: /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}$/,
  AlphaNumeric: /^[A-Za-z0-9]+( [A-Za-z0-9]+)*$/,
  AlphaNumericUnderscore: /^[A-Za-z0-9]+(_[A-Za-z0-9]+)*$/,
  AplhaNumericHyphenUnderscore: /^[A-Za-z0-9]+((-|_)[A-Za-z0-9]+)*$/,
  AlphaNumericSeperators: /^[A-Za-z0-9]+((-|_|:)[A-Za-z0-9]+)*$/,
  LanguageCode: /^[a-zA-Z]{2}-[a-zA-Z]{2}$/,
  MarketCode: /^[a-zA-Z]{2}$/,
  UserName: /^((\([A-Za-z\s]+\)+(\s+))|([A-Za-z0-9]+(,\s|\s))|[A-Za-z0-9])+[A-Za-z0-9]+((,\s[A-Za-z0-9])|(\s(\([A-Za-z\s]+\))))?$/
}

export const DefaultProperty = [{'code':'ALL','name':'ALL'}]

export const FeatureApplicabilities = ['FTR', 'OPT']

export const ProductApplicabilities = ['PROD']
