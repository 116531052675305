import { createAsyncThunk } from '@reduxjs/toolkit';
import { PropertyApi } from '../../api/PropertyApi';

//Property thunks
//To get the paginated property data
export const getProperties = createAsyncThunk( 'property/getProperties', async ( action:{ token: string, page:number, limit:number, searchCode?: string } )=>{
  return PropertyApi.getProperties( action );
} )

//To get a property details
export const getPropertyDetails = createAsyncThunk( 'property/getPropertyDetails', async ( action:{ token: string, searchCode?: string } )=>{
  return PropertyApi.getPropertyDetails( action );
} )

//to update a property
export const updateProperty = createAsyncThunk( 'property/updateProperty', async ( action:{ token: string, request } )=>{
  return PropertyApi.updateProperty( action );
} )
 
//To create a property
export const createProperty = createAsyncThunk( 'property/createProperty', async ( action:{ token: string, request } )=>{
  return PropertyApi.createProperty( action );
} )

//To delete a property
export const deleteProperty = createAsyncThunk( 'property/deleteProperty', async ( action:{ token: string, request } )=>{
  return PropertyApi.deleteProperty( action );
} )

//To get paginated property associations
export const getPropertyAssociations = createAsyncThunk( 'property/getPropertyAssociations', async ( action: {token: string, page:number, limit:number, searchCode?:string} )=>{
  return PropertyApi.getPropertyAssociations( action );
} )

//To get paginated feature property associations
export const getFeaturePropertyAssociations = createAsyncThunk( 'property/getFeaturePropertyAssociations', async ( action: {token: string, page:number, limit:number, searchCode?:string,productId?:string} )=>{
  return PropertyApi.getFeaturePropertyAssociations( action );
} )

//Read all properties to show on Property associations tab
export const getAllProperties = createAsyncThunk( 'property/getAllProperties', async ( action:{ token: string } )=>{
  return PropertyApi.getProperties( {token: action.token,page:0, limit:0, searchCode:'' } );
} )
