import React, { useEffect } from 'react';
import { Button,Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation,Trans } from 'react-i18next';
import UploadIcon from '@mui/icons-material/Upload';
import { initDropZone } from '../../components/Dropzone';
import { EDataMaintenanceType, EImportStatus } from '../../data/Constants';
import { setAlertMessage } from '../../store/states/AlertMessageSlice';
import { removeUploadFile, setUploadFile } from '../../store/states/DataMaintenanceSlice';
import { AppState } from '../../store/AppStore';

export const FileUpload = ( props ) => {
  const propFileState = useSelector( ( state: AppState ) => state.dataMaintainance.uploadFile );

  const {t} = useTranslation();
  const dispatch = useDispatch();

  useEffect( ()=>{
    if( propFileState.file?.name || propFileState.importDropzone ) {
      dispatch( removeUploadFile() );
    }
  }, [] )

  const handleCloseImport = ()=>{
    dispatch( removeUploadFile() );//remove upload file after click cancel button
  }

  const handleUploadClick = () => {
    propFileState.importDropzone.processQueue();//trigger the file upload to the API using dropzone while click upload button
  };

  const handleClickImport = ()=>{
    if( propFileState.importDropzone != null ) { // remove existing dropzone if any
      propFileState.importDropzone.destroy();
    }
    const dropZone = initDropZone( props.token,props.type,showAlertMessage );//initialize the dropzone to get the files from folder while click import button
    dispatch( setUploadFile( { file: {}, importDropzone: dropZone } ) )
   
    dropZone?.hiddenFileInput?.click();
  }

  const showAlertMessage = ( type:string,fileType:string, fileSize:number, fileName:string )=>{ //show alert message while import or upload file
    switch ( type ) {
      case EImportStatus.Success:
        dispatch( setAlertMessage( {show:true,message:props.type === EDataMaintenanceType.ProductHierarchy ? <Trans i18nKey="messages.success.upload.productHierarchy" components={ { 1: <br /> } } /> : t( 'messages.success.upload.general' ),type:'success'} ) );
        break;
      case EImportStatus.InvalidType:
        dispatch( setAlertMessage( { show:true, message:t( 'messages.fail.fileFormat',{format: fileType} ), type:EImportStatus.Error} ) );
        break;
      case EImportStatus.InvalidSize:
        dispatch( setAlertMessage( { show:true, message:t( 'messages.fail.fileSize',{size:fileSize} ), type:EImportStatus.Error} ) );
        break;
      case EImportStatus.Error:
        dispatch( setAlertMessage( {show:true,message:t( 'messages.fail.upload' ), type:EImportStatus.Error} ) );
        break;
      case EImportStatus.InvalidFileName:
        dispatch( setAlertMessage( { show:true, message:t( 'messages.fail.fileName',{fileName:fileName, fileNameSize:80} ), type:EImportStatus.Error} ) );
        break;
      case EImportStatus.InvalidZipFileName:
        dispatch( setAlertMessage( { show:true, message:t( 'messages.fail.fileName',{fileName:fileName, fileNameSize:50} ), type:EImportStatus.Error} ) );
        break;
    }
  }

  return <>
    <Box className="file-upload-box align-center">
      {!propFileState?.file?.name && <div id="dropzone-upload">
        <Button onClick={ handleClickImport } className="text-capitalize common-btn" variant="contained" startIcon={ <UploadIcon/> }>
          {t( 'button.import' )}
        </Button>
      </div>}
      {propFileState?.file.name && <>
        <span className="span-overflow">{propFileState.file?.name}</span> &emsp;
        <><Button className="text-capitalize common-btn" id="fileupload-cancel" variant="contained" color="error" onClick={ handleCloseImport } >
          {t( 'button.cancel' )}
        </Button> &emsp;</>
        <><Button className="text-capitalize common-btn" id="fileupload-upload" variant="contained" onClick={ handleUploadClick }>
          {t( 'button.upload' )}
        </Button></>
      </>}
    </Box>
  </>
}
